import React from 'react';
import { Grid, GridItem, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import Sidebar from '../DeskUserView/Sidebar';
import FramePreview from '../Frame/FramePreview';
import type { State } from 'src/types/state';

const Page = ({ children }: { children: React.ReactNode }) => {
  const viewConfig = useSelector((state: State) => state.viewConfig);

  const isNavigationGotItems = () => {
    return !!viewConfig?.navigation?.items?.length;
  };
  const sidebarColumnSpan = useBreakpointValue({ base: 1, lg: 2 });
  const contentColumnSpan = useBreakpointValue({ base: 1, lg: isNavigationGotItems() ? 8 : 10 });

  return (
    <div className="container pageWrapper">
      {useBreakpointValue({
        base: (
          <Flex direction="column">
            {isNavigationGotItems() && (
              <GridItem colSpan={sidebarColumnSpan}>
                <Sidebar />
              </GridItem>
            )}
            <GridItem colSpan={contentColumnSpan} style={{ margin: '1rem' }}>
              {children}
            </GridItem>
          </Flex>
        ),
        lg: (
          <Grid templateColumns="repeat(10, 1fr)" gap={4}>
            {isNavigationGotItems() && (
              <GridItem colSpan={sidebarColumnSpan}>
                <Sidebar />
              </GridItem>
            )}
            <GridItem colSpan={contentColumnSpan} style={{ margin: '1rem' }}>
              {children}
            </GridItem>
          </Grid>
        )
      })}

      <FramePreview />
    </div>
  );
};

export default Page;
