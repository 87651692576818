import React, { createElement, useEffect, useRef } from 'react';

type Props = {
  html: string;
};

function InnerHtml(props: Props) {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!props.html || !divRef.current) throw new Error("html prop can't be null");
    if (!isFirstRender.current) return;
    isFirstRender.current = false;

    const slotHtml = document.createRange().createContextualFragment(props.html); // Create a 'tiny' document and parse the html string
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    divRef.current.innerHTML = ''; // Clear the container
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [props.html, divRef]);

  return createElement('div', { ref: divRef });
}

export default InnerHtml;
