import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Box, Stack, Icon, Text, Flex } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import type { State } from 'src/types/state';
import type { NavigationItem } from 'src/types/Config';

export default function Sidebar() {
  const location = useLocation();
  const viewConfig = useSelector((state: State) => state.viewConfig);

  const renderLinkContent = (item: NavigationItem, isCurrentPath: boolean = false) => {
    return (
      <Box p={2} rounded={'lg'} role={'group'} _hover={{ bg: 'teal.50' }} bg={isCurrentPath ? 'teal.50' : undefined}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text transition={'all .3s ease'}>{item.name}</Text>
          </Box>

          <Flex
            flex={1}
            opacity={isCurrentPath ? '100%' : 0}
            align={'center'}
            justify={'flex-end'}
            transition={'all .3s ease'}
            transform={isCurrentPath ? 'translateX(0)' : 'translateX(-10px)'}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          >
            <Icon color={'teal.400'} w={5} h={5} as={ChevronRightIcon} aria-hidden="true" />
          </Flex>
        </Stack>
      </Box>
    );
  };

  return (
    <Box
      boxShadow="base"
      rounded="lg"
      style={{
        margin: '1rem 0 1rem 1rem',
        padding: '1rem'
      }}
      className="sidebar"
    >
      {viewConfig?.navigation.items.map((item) => {
        if (item.isNewTab) {
          return (
            <a key={item.path} href={item.path} target="_blank" rel="noopener noreferrer">
              {renderLinkContent(item)}
            </a>
          );
        }

        const isCurrentPath = location.pathname === item.path;
        return (
          <Link key={item.path} to={item.path} {...(isCurrentPath && { 'aria-current': 'page' })}>
            {renderLinkContent(item, isCurrentPath)}
          </Link>
        );
      })}
    </Box>
  );
}
