// TODO: showDisconnectedNotification logic

import moment from 'moment';
import { Cookies } from 'react-cookie';
import type { Socket } from 'socket.io-client';
import type { Middleware, MiddlewareAPI } from 'redux';

import { AUTH_SUCCESS } from '../actions';
import { logOut } from '../actions/authActions';
import { eventEmitter } from 'src/utils/eventEmmiter';
// import { showDisconnectedNotification } from 'src/actions/notificationsActions';
import SocketInstance from 'src/socket/instance';
import { ThunkAppDispatch } from '../store';
import BackendApi from 'src/api/BackendApi';
import { fetchPersonalData } from '../actions/personalDataActions';
import { PersonalData } from 'src/types/Profile';
import type { State } from 'src/types/state';

interface EntityEventMessage {
  data: {
    contentId: number;
    entityId: string;
  };
  lastAckedMessage: number;
  messageIdentifier: string;
}
type API = MiddlewareAPI<ThunkAppDispatch, State>;

export const socketInitMiddleware: Middleware<{}, State, ThunkAppDispatch> = (api: API) => (next) => async (action) => {
  const returnValue = next(action);
  const cookies = new Cookies();

  if (AUTH_SUCCESS === action.type && !cookies.get('EID')) {
    const [{ payload: personalData }, ticketTypes] = await Promise.all([
      api.dispatch(fetchPersonalData()),
      BackendApi.getTicketTypes()
    ]);
    (ticketTypes.allowedTicketTypes as Record<string, unknown>[]).map((ticketType) =>
      SocketInstance.joinRoom(ticketType.name as string)
    );

    if (personalData === undefined) {
      return;
    }

    SocketInstance.initialize((personalData as unknown as PersonalData).UID as string, {
      disconnect: () => (reason: Socket.DisconnectReason) => {
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          if (localStorage.getItem('loggedIn')) {
            api.dispatch(logOut({ type: 'manual', enableToast: false }));
          }
        } else {
          // Disconnected due to network issues or service inaccessibility
          // api.dispatch(refreshToken());
        }

        SocketInstance.socket.io.opts.query = {
          ...(!!localStorage.getItem('lastAckedMessage') && {
            lastAckedMessage: localStorage.getItem('lastAckedMessage')
          })
        };
        // api.dispatch(showDisconnectedNotification(true));
      },

      connect: () => () => {
        localStorage.setItem('lastAckedMessage', String(moment().valueOf()));
        // api.dispatch(showDisconnectedNotification(false));
      },

      connect_error: () => () => {
        // api.dispatch(refreshToken());
        // api.dispatch(showDisconnectedNotification(true));
      },

      entityAttached: (acknowledgeMessage) => (payload: EntityEventMessage) => {
        const { data, lastAckedMessage, messageIdentifier } = payload;

        localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
        eventEmitter.emit('entityAttached', data);

        acknowledgeMessage?.(messageIdentifier);
      },

      entityDetached: (acknowledgeMessage) => (payload: EntityEventMessage) => {
        const { data, lastAckedMessage, messageIdentifier } = payload;

        localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
        eventEmitter.emit('entityDetached', data);

        acknowledgeMessage?.(messageIdentifier);
      }
    });
  }

  return returnValue;
};
