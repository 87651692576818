import { set } from 'lodash';
import queryString from 'query-string';

import FeatureFlags from '../api/FeatureFlags';
import type { FormAttachmentPayload } from 'src/types/Form';

export const getUrlSearchParam = (name: string) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  return urlParams.get(name) || undefined;
};

export const clearSearchParams = () => {
  const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
  history.replaceState({}, document.title, newUrl);
};

export const isAuthEnabled = () => {
  const isEID = FeatureFlags.isFlagOn('ENABLE_PORTAL_EID_AUTH');
  const isDesk = FeatureFlags.isFlagOn('ENABLE_PORTAL_DESK_AUTH');

  return isEID || isDesk;
};

export const getSearchParamsWithTag = (
  parameters: { type: string; value: string }[],
  defaultParameters: { [key: string]: unknown }
) => {
  return `?${queryString.stringify(
    parameters.reduce(
      (acc, parameter) => {
        if (parameter.type === 'tags') {
          if (!Array.isArray(acc['tags'])) {
            acc['tags'] = [];
          }
          (acc['tags'] as string[]).push(parameter.value);
        } else {
          acc[parameter.type] = parameter.value;
        }
        return acc;
      },
      { ...defaultParameters } as { [key: string]: unknown }
    )
  )}`;
};

// Mutation function
export const convertAttachmentsInFormData = async (formData: FormData, destination: Record<string, unknown>) => {
  const fileReaderPromises: Promise<void>[] = [];
  formData.forEach((value, name) => {
    if (name === 'attachment') {
      const reader = new FileReader();
      fileReaderPromises.push(
        new Promise<void>((resolve) => {
          reader.onload = (event) => {
            if (event.target?.result && (value as File).name) {
              const base64Content = event.target.result.toString().split(',')[1];
              (destination.base64Attachments as FormAttachmentPayload[]).push({
                fileName: (value as File).name,
                contentType: (value as File).type,
                base64Content
              });
            }
            resolve();
          };
        })
      );
      reader.readAsDataURL(value as File);
    } else {
      set(destination, name, value);
    }
  });

  await Promise.all(fileReaderPromises);
};
