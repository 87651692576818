import BackendApi from './BackendApi';
import ApiConfig from './ApiConfig';

const ACTIVE_FRONTEND_FEATURE_FLAGS = ['ENABLE_PORTAL_EID_AUTH', 'ENABLE_PORTAL_DESK_AUTH'] as const;

export type TFeatureFlag = (typeof ACTIVE_FRONTEND_FEATURE_FLAGS)[number];

export default class FeatureFlags {
  static flags: TFeatureFlag[] = [];

  static setFlags(incomingFlags: TFeatureFlag[]) {
    this.flags = incomingFlags;
  }

  static isFlagOn(flag: TFeatureFlag) {
    return this.flags.includes(flag);
  }

  static getFeatureFlags = async (): Promise<TFeatureFlag[]> =>
    BackendApi.axios
      .post(`${ApiConfig.getConfig().PORTAL_API_URL}/featureFlags`, { featureFlags: ACTIVE_FRONTEND_FEATURE_FLAGS })
      .then((response) => {
        FeatureFlags.setFlags(Object.values(response.data));
        return response.data;
      });
}
