import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFela } from 'react-fela';
import { Spinner, Text, Flex } from '@chakra-ui/react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import Page from '../Page/Page';
import LogIn from '../LogIn/LogIn';
import NabBar from '../NavBar/NavBar';
import HashIdentity from '../HashIdentity/HashIdentity';
import FormIdentity from '../FormIdentity/FormIdentity';
import MainView from '../DeskUserView/MainView';
import SummaryView from '../SummaryView/SummaryView';
import EntityView from '../DeskUserView/EntityView/EntityView';
import ApiConfig from '../../api/ApiConfig';
import { isAuthEnabled } from 'src/utils';
import { defaultStyles } from 'src/utils/constants';
import type { State } from 'src/types/state';

const Main = () => {
  const { renderer } = useFela();
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state: State) => state.auth.isLoggedIn);
  const viewConfig = useSelector((state: State) => state.viewConfig);
  const config = useSelector((state: State) => state.config);
  const [{ EID }] = useCookies(['EID']);

  useEffect(() => {
    localStorage.setItem('initial_title', document.title);

    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    const faviconUrl = ApiConfig.getConfig().FAVICON_URL;
    if (favicon && faviconUrl) {
      favicon.href = faviconUrl;
    }
  }, []);

  if (config?.styles) {
    renderer.renderStatic(defaultStyles + config?.styles);
  }

  const mountStructure = useCallback(() => {
    if (isAuthEnabled() && !isLoggedIn) {
      return <LogIn />;
    }

    /**
     * E-identified user
     */
    if (EID) {
      return (
        <>
          <NabBar />
          <Switch>
            <Route
              path="/form/:id"
              render={() => (
                <Page>
                  <FormIdentity />
                </Page>
              )}
            />
            <Route
              path="/"
              render={() => (
                <Page>
                  <HashIdentity />
                </Page>
              )}
            />
          </Switch>
        </>
      );
    }

    /**
     * Legacy view without authorization
     */
    if (!isAuthEnabled()) {
      return (
        <Switch>
          <Route path="/" component={HashIdentity} />
        </Switch>
      );
    }

    /**
     * Desk user
     */
    return handleDeskUserView();
  }, [isLoggedIn, viewConfig]);

  const handleDeskUserView = () => {
    if (viewConfig === null) {
      return (
        <Flex className="contentWrapper" alignItems="center" justifyContent="center" direction="column">
          <Spinner size="xl" color="blue.500" />
          <Text mt={4} fontSize="xl" fontWeight="bold">
            {t('labels.loading')}
          </Text>
        </Flex>
      );
    }

    if (viewConfig === undefined) {
      return (
        <Flex className="contentWrapper" alignItems="center" justifyContent="center" direction="column">
          <Text fontSize="xl" fontWeight="bold">
            {t('labels.app_config_load_failed')}
          </Text>
          <Text fontSize="md">{t('labels.service_unavailable')}</Text>
        </Flex>
      );
    }

    return (
      <>
        <NabBar />
        <Switch>
          {viewConfig.pages.items.map((page) => (
            <Route
              key={page.url}
              path={page.url}
              render={() => <div dangerouslySetInnerHTML={{ __html: page.html }} />}
            />
          ))}
          <Route path="/entity/:id" component={EntityView} />
          <Route path="/form/:id" component={FormIdentity} />
          <Route path="/summary" component={SummaryView} />
          <Route path="/" component={MainView} />
        </Switch>
      </>
    );
  };

  return <>{mountStructure()}</>;
};

export default Main;
