import React, { useState } from 'react';
import { Stack, Box, Heading, Text, Textarea, Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';

import type { Comment as IComment } from './types/Comment';

interface CommentSectionProps {
  comments: IComment[];

  sendComment: (comment: Pick<IComment, 'content' | 'files'>) => Promise<void>;
}

function decodeText(text: string) {
  var txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
}

const Comment = ({ title, content, created, ...rest }: { title: string; content: string; created: number }) => {
  return (
    <Box p={5} backgroundColor={['White']} shadow="md" borderWidth="1px" {...rest} rounded="md" w="99%" mb="3">
      <Heading as="h4" fontSize="md">
        {title}
      </Heading>
      <Text mt={4}>{decodeText(content)}</Text>
      <Text as="sub"> {dayjs.unix(created).format('DD.MM.YYYY hh:mm')}</Text>
    </Box>
  );
};

export const CommentSection = (props: CommentSectionProps) => {
  const [comment, setComment] = useState({ content: '', files: undefined });
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const onChange = (inputData: FileList | string | null, field: string) => {
    setComment({ ...comment, [field]: inputData });
  };

  const onSubmit = async () => {
    await props.sendComment(comment);
    setComment({ ...comment, content: '' });
    resetFileInput();
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      <Stack align="center" spacing={4}>
        {props.comments
          ? props.comments.map((comment) => {
              return (
                <Comment
                  key={comment.created}
                  title={comment?.metaData?.from}
                  content={comment.content}
                  created={comment.created}
                />
              );
            })
          : null}
      </Stack>
      <Heading fontSize="md">
        <AddIcon />
        <span className="portalNewTicketHeading"> Lisää uusi kommentti</span>
      </Heading>
      <Textarea
        value={comment.content}
        bg="white"
        colorScheme="white"
        onChange={(e) => {
          onChange(e.target.value, 'content');
        }}
      />
      <div>
        <input
          id="fileupload"
          ref={fileInputRef}
          type="file"
          multiple
          onChange={(e) => onChange(e.target.files, 'files')}
        />
      </div>
      <Button style={{ marginTop: '10px' }} colorScheme="blue" onClick={() => onSubmit()}>
        Lähetä
      </Button>
    </div>
  );
};

export default CommentSection;
