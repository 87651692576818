import React, { useState } from 'react';
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Stack,
  useToast,
  Heading
} from '@chakra-ui/react';

import BackendApi from './api/BackendApi';
import { getUrlSearchParam } from './utils';

export interface TicketTypeEntity {
  name: string;
  value: string;
  params?: { customerPortalVisible?: boolean };
}

interface EntityInfoProps {
  customerData: Record<string, string>;
  ticketTypeEntity: TicketTypeEntity[];
}

export const CustomerEntityInfo = (props: EntityInfoProps) => {
  const getEditableCustomerData = () => {
    return props.ticketTypeEntity?.reduce((accumulator: Record<string, string>, data) => {
      if (data.params && data.params.customerPortalVisible) {
        accumulator[data.value] = props.customerData[data.value];
      }

      return accumulator;
    }, {});
  };

  const toast = useToast();
  const [editedEntity, setEditedEntity] = useState(getEditableCustomerData());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitEdit = async () => {
    setIsSubmitting(true);

    try {
      await BackendApi.updateEntityByHash(editedEntity, { hash: getUrlSearchParam('hash') });
      toast({
        title: 'Tiedot päivitetty',
        description: 'Tiedot päivitetty onnistuneesti',
        status: 'success',
        duration: 4000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'Päivitys epäonnistui',
        description: 'Tietojen päivittäminen epäonnistui',
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="ticketContainer">
      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton className="ticketAccordionContainer entityAccordion">
            <Box flex="1" textAlign="left">
              <Heading as="h4" className="ticketTitle">
                Asiakastiedot
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>

          {props.ticketTypeEntity ? (
            <AccordionPanel pt={0} pl={0} pr={0}>
              <div className="ticketInnerContainer" style={{ padding: '10px' }}>
                {props.ticketTypeEntity.map((dataObj) => {
                  if (dataObj.params && dataObj.params.customerPortalVisible) {
                    return (
                      <Stack key={dataObj.name}>
                        <FormControl>
                          <FormLabel htmlFor={dataObj.name}>{dataObj.name}</FormLabel>
                          <Input
                            className="customerPortalInput"
                            value={editedEntity[dataObj.value]}
                            style={{ backgroundColor: 'white' }}
                            onChange={(event) =>
                              setEditedEntity({
                                ...editedEntity,
                                [dataObj.value]: event.target.value
                              })
                            }
                          />
                        </FormControl>
                      </Stack>
                    );
                  }
                })}

                <Button
                  isLoading={isSubmitting}
                  loadingText="Päivitetään tietoja"
                  onClick={() => submitEdit()}
                  colorScheme="blue"
                  mt="2"
                >
                  Päivitä
                </Button>
              </div>
            </AccordionPanel>
          ) : null}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CustomerEntityInfo;
