import axios from 'axios';

interface ApiConfigurationParameters {
  PORTAL_API_URL: string;
  SUPPORT_FRONTEND: string;
  REAL_TIME_SERVICE_ADDRESS: string;
  DEFAULT_LANGUAGE: string;
  EID_LOGIN_BUTTON: string | undefined;
  FAVICON_URL: string | undefined;
}

export default class ApiConfig {
  static version: string = '';
  static isVersionCheckPostponed: boolean = false;

  static config: ApiConfigurationParameters = {
    PORTAL_API_URL: '',
    SUPPORT_FRONTEND: '',
    REAL_TIME_SERVICE_ADDRESS: '',
    DEFAULT_LANGUAGE: '',
    EID_LOGIN_BUTTON: undefined,
    FAVICON_URL: undefined
  };

  static async fetchConfig() {
    return axios.get('/config/config.json').then((resp) => {
      ApiConfig.setConfig(resp.data);
    });
  }

  static setConfig(incomingConfig: ApiConfigurationParameters) {
    this.config = {
      ...this.config,
      ...incomingConfig
    };
  }

  static getConfig() {
    return this.config;
  }
}
