import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useColorModeValue,
  Button
} from '@chakra-ui/react';

import { useAppThunkDispatch } from 'src/store/store';
import { logOut } from 'src/store/actions/authActions';
import { clearSearchParams, getUrlSearchParam } from 'src/utils';

interface TimeLeft {
  minutes: number;
  seconds: number;
}

function calculateTimeLeft(timestamp: number | undefined) {
  const timeLeft: TimeLeft = {
    minutes: 0,
    seconds: 0
  };
  if (!timestamp) return timeLeft;

  const difference = timestamp - Date.now() / 1000;
  if (difference > 0) {
    timeLeft.minutes = Math.floor(difference / 60);
    timeLeft.seconds = Math.floor(difference % 60);
  }

  return timeLeft;
}

const EIDSessionCounter = () => {
  const [{ EID }] = useCookies(['EID']);
  if (!EID) {
    return null;
  }

  const { t } = useTranslation();
  const bgGreyColor = useColorModeValue('gray.50', 'gray.700');
  const textGreyColor = useColorModeValue('gray.800', 'gray.100');
  const bgGreenColor = useColorModeValue('green.100', 'green.900');
  const textGreenColor = useColorModeValue('green.800', 'green.200');
  const dispatch = useAppThunkDispatch();
  const [sessionExpirationPeriod, setSessionExpirationPeriod] = useState(
    Number(localStorage.getItem('sessionExpirationPeriod'))
  );
  const [targetTimestamp, setTargetTimestamp] = useState(Number(localStorage.getItem('sessionExpirationTimestamp')));
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(targetTimestamp));

  useEffect(() => {
    const timer = setTimeout(() => {
      const result = calculateTimeLeft(targetTimestamp);

      if (result.minutes === 0 && result.seconds === 0) {
        dispatch(logOut({ type: 'manual' }));
      }

      setTimeLeft(result);
    }, 100);

    return () => clearTimeout(timer);
  });

  const handleUserActivity = () => {
    const sessionExpirationTimestamp = moment().add(localStorage.getItem('sessionExpirationPeriod'), 'minutes').unix();
    localStorage.setItem('sessionExpirationTimestamp', String(sessionExpirationTimestamp));
    setTargetTimestamp(sessionExpirationTimestamp);
  };

  useEffect(() => {
    const sessionExpirationTimestamp = getUrlSearchParam('sessionExpiration');
    if (sessionExpirationTimestamp) {
      localStorage.setItem('sessionExpirationTimestamp', sessionExpirationTimestamp);
      setTargetTimestamp(parseInt(sessionExpirationTimestamp));
    }

    const sessionExpirationPeriod = getUrlSearchParam('sessionExpirationPeriod');
    if (sessionExpirationPeriod) {
      localStorage.setItem('sessionExpirationPeriod', sessionExpirationPeriod);
      setSessionExpirationPeriod(parseInt(sessionExpirationPeriod));
    }

    clearSearchParams();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
    };
  }, []);

  // Show modal when less than 20% of the session time left
  const isShowModal =
    (timeLeft.minutes || timeLeft.seconds) &&
    timeLeft.minutes * 60 + timeLeft.seconds < (sessionExpirationPeriod * 60) / 5;

  return isShowModal ? (
    <Modal
      isOpen={true}
      onClose={() => console.info('[timer debug]: Session is prolonged')}
      isCentered
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent backgroundColor={bgGreyColor} borderRadius="md">
        <ModalHeader fontSize="lg" fontWeight="bold" color={textGreyColor}>
          {t('labels.session_expiration')}
        </ModalHeader>

        <ModalBody>
          <Text mb={4} color={textGreyColor}>
            {t('labels.session_close_to_expire')}
          </Text>

          <Box textAlign="center" p={3} bg={bgGreenColor} borderRadius="md">
            <Text fontSize="xl" fontWeight="bold" color={textGreenColor}>
              {t('labels.session_left', {
                minutes: String(timeLeft.minutes).padStart(2, '0'),
                seconds: String(timeLeft.seconds).padStart(2, '0')
              })}
            </Text>
          </Box>

          <Button onClick={() => handleUserActivity()} width="100%" mt="1rem">
            {t('buttons.prolong_session')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : null;
};

export default EIDSessionCounter;
