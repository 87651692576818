import socketIo from 'socket.io-client';
import type { Socket } from 'socket.io-client';

import ApiConfig from '../api/ApiConfig';

class SocketInstance {
  public socket!: Socket;
  private isInitialized: boolean;
  public _userId: string | null;

  constructor() {
    this.isInitialized = false;
    this._userId = null;
  }

  private acknowledgeMessage = (messageIdentifier: string): void => {
    this.socket?.emit('acknowledgedMessages', { messageIdentifier });
  };

  public initialize = async (
    userId: string,
    handlers: { [key: string]: (fn?: (messageIdentifier: string) => void) => (...args: any[]) => void }
  ) => {
    if (this.isInitialized) {
      return;
    }

    this._userId = userId;
    this.isInitialized = true;

    this.socket = socketIo(ApiConfig.getConfig().REAL_TIME_SERVICE_ADDRESS, {
      reconnectionDelay: 5000,
      reconnectionDelayMax: 10000,
      transports: ['websocket'],
      path: '/socket.io/',
      withCredentials: true,
      query: {
        UID: this._userId
      }
    });

    this.socket.on('connect', handlers.connect());
    this.socket.on('connect_error', handlers.connect_error());
    this.socket.on('disconnect', handlers.disconnect());

    this.socket.on('entityAttached', handlers.entityAttached(this.acknowledgeMessage));
    this.socket.on('entityDetached', handlers.entityDetached(this.acknowledgeMessage));
  };

  public disconnect = () => {
    if (this.socket !== undefined) {
      if (this.socket.connected) {
        this.socket.disconnect();
      }
      this.isInitialized = false;
      console.debug('User has disconnected from realtimeservice, resetting');
    }
  };

  public joinRoom = (id: string) => {
    if (this.socket === undefined || !this.socket.connected) {
      setTimeout(() => {
        this.joinRoom(id);
      }, 1000);
    } else {
      this.socket.emit('joinRoom', {
        room: id,
        UID: this._userId
      });
    }
  };

  public emit = (event: string, payload: { [key: string]: unknown }) => this.socket?.emit(event, payload);

  public leaveRoom = (id: number | string) => {
    if (this.socket === undefined || !this.socket.connected) {
      setTimeout(() => {
        this.leaveRoom(id);
      }, 1000);
    } else {
      this.socket.emit('leaveRoom', {
        room: id,
        UID: this._userId
      });
    }
  };
}

export default new SocketInstance();
